import { Fragment, useEffect, useState } from 'react'
import { Menu, Transition } from '@headlessui/react'
import {
  MenuAlt2Icon,
  DesktopComputerIcon,
  ClipboardListIcon,
  ViewListIcon,
  CurrencyDollarIcon,
  TemplateIcon,
  ClipboardCheckIcon,
  SearchIcon,
  PlusCircleIcon,
  CogIcon,
  LogoutIcon,
  QuestionMarkCircleIcon,
  UserIcon,
  TagIcon,
  SparklesIcon
} from '@heroicons/react/outline'
import {
  UserIcon as UserSolidIcon
} from '@heroicons/react/solid';
import { Link } from 'react-router-dom'
import { logoutAsync } from '../features/auth/authSlice'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useHistory } from 'react-router-dom'
import SwitchRoleSelect from './general/SwitchRoleSelect'
import FacebookIcon from '@material-ui/icons/Facebook'
import {
  ADMIN_ROLE,
  ALL_ROLES,
  BASIC_ROLE,
  COURSE_ONLY_ROLE,
  COACH_ROLE,
  PREMIUM_ROLE
} from '../constants'

import NavigationItem from './general/navigation/NavigationItem'
import NavigationLink from './general/navigation/NavigationLink'
import classNames from 'classnames'

const userNavigation = [{ name: 'Profile', href: '/profile' }]

const DashboardTemplate = ({ children }) => {
  const [sidebarOpen, setSidebarOpen] = useState(false)
  const dispatch = useDispatch()
  const location = useLocation()
  const history = useHistory()
  const [navigation, setNavigation] = useState([
    {
      name: 'Dashboard',
      href: '/',
      icon: DesktopComputerIcon,
      current: true,
      requiredRoles: ALL_ROLES
    },
    {
      name: 'Ai Tool kit',
      href: '/ai-tool-kit',
      icon: SparklesIcon,
      current: true,
      requiredRoles: ALL_ROLES
    },
    {
      name: 'Courses',
      href: '/courses',
      icon: ClipboardListIcon,
      current: false,
      requiredRoles: ALL_ROLES,
      onClick: () => {
        const isAlreadyOnCoursePage = history.location.pathname.includes('/course')
        const isOnTabletOrMobileSize = window.innerWidth < 1024
        if (isAlreadyOnCoursePage && isOnTabletOrMobileSize) {
          document.getElementById('course-modules').scrollIntoView({ behavior: 'smooth' })
        }
        history.push('/courses')
        setSidebarOpen(false)
      }
    },
    {
      name: 'Resources',
      href: '/resources',
      icon: ViewListIcon,
      current: false,
      requiredRoles: ALL_ROLES
    },
    {
      name: 'Power Profit Tool',
      href: '/profit-calculator',
      icon: CurrencyDollarIcon,
      current: false,
      requiredRoles: ALL_ROLES
    },
    {
      name: 'Move The Needle',
      href: '/move-the-needle',
      icon: TemplateIcon,
      current: false,
      requiredRoles: [PREMIUM_ROLE, COACH_ROLE]
    },
    {
      name: 'Master Template',
      href: '/move-the-needle',
      icon: TemplateIcon,
      current: false,
      requiredRoles: [ADMIN_ROLE]
    },
    {
      name: 'Product Qualifier',
      href: '/product-qualifier',
      icon: ClipboardCheckIcon,
      current: false,
      requiredRoles: [COURSE_ONLY_ROLE, PREMIUM_ROLE, ADMIN_ROLE, COACH_ROLE]
    },
    {
      name: 'User Dashboard',
      href: '/users',
      icon: UserIcon,
      current: false,
      requiredRoles: [ADMIN_ROLE, COACH_ROLE]
    },
    {
      name: 'Tag Management',
      href: '/tag-management',
      icon: TagIcon,
      current: false,
      requiredRoles: [ADMIN_ROLE]
    }
  ])

  const { currentUser } = useSelector((state) => state.auth)
  const isBasicRole = currentUser?.role === BASIC_ROLE
  const isCourseOnlyRole = currentUser?.role === COURSE_ONLY_ROLE
  const isPremiumRole = currentUser?.role === PREMIUM_ROLE

  useEffect(() => {
    const newNavigation = navigation.map((nav) => {
      const isDashboardNav = nav.href === '/'
      const isOnDashboard = history.location.pathname === '/'
      const isOnCurrentNavPath = history.location.pathname.includes(nav.href)
      const isCurrentNavActive =
        (isOnDashboard && isDashboardNav) || (isOnCurrentNavPath && !isDashboardNav)
      if (isCurrentNavActive) nav.current = true
      else nav.current = false
      return nav
    })

    setNavigation(newNavigation)
    // inluding navigation in the dependency array would cause an infinite loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history.location.pathname])

  const rainmakersFacebookGroupLink =
    currentUser?.role === PREMIUM_ROLE
      ? 'https://www.facebook.com/groups/rainmakersvip'
      : 'https://www.facebook.com/groups/rainmakerchallenge'

  return (
    <div className="h-screen flex overflow-hidden bg-white-200">
      <div
        className={classNames([
          'fixed top-0 left-0 right-0 bottom-0 w-screen h-screen z-40 lg:hidden bg-black bg-opacity-40 cursor-pointer opacity-0 transition-opacity',
          {
            'hidden opacity-100 lg:hidden': !sidebarOpen,
            'opacity-100': sidebarOpen
          }
        ])}
        onClick={() => setSidebarOpen(false)}
      />
      <div
        className={classNames([
          'overflow-hidden h-screen max-h-full fixed top-0 left-0 transition-all lg:static z-50 lg:z-10',
          {
            '-left-full': !sidebarOpen
          }
        ])}
      >
        <div className="flex flex-col w-290 bg-primary h-screen max-h-full pt-6 pb-8 px-4">
          <div className="mb-5.5">
            <Link to="/">
              <img src="/images/white-rainmaker-family.svg" alt="" />
            </Link>
          </div>
          <div className="space-y-1 flex-1 max-h-full overflow-y-auto">
            {navigation.map((item, idx) => (
              <NavigationItem
                key={idx}
                item={item}
                currentUser={currentUser}
                onNavigate={() => setSidebarOpen(false)}
              />
            ))}
            {(isBasicRole || isCourseOnlyRole) && (
              <NavigationLink
                name="Apply To Upgrade"
                target="_blank"
                href={
                  isBasicRole
                    ? 'https://therainmakerchallenge.com/finishline'
                    : 'https://rainmakerfamily.com/mastermind/?el=courseonly'
                }
              >
                <UserIcon />
              </NavigationLink>
            )}
            <NavigationLink
              href={rainmakersFacebookGroupLink}
              target="_blank"
              name="Facebook Group"
            >
              <FacebookIcon />
            </NavigationLink>
            {!isBasicRole && !isCourseOnlyRole && (
              <NavigationLink
                href="https://searchrainmakers.com"
                target="_blank"
                name="SearchRainmakers.com"
              >
                <SearchIcon />
              </NavigationLink>
            )}
            {isPremiumRole && (
              <NavigationLink
                href="https://rainmakerupgrades.com"
                target="_blank"
                name="RainmakerUpgrades.com"
              >
                <PlusCircleIcon />
              </NavigationLink>
            )}
          </div>
          <div className="pt-6 mt-6 border-t border-solid border-opacity-20 border-white-500">
            <div className="space-y-1">
              <NavigationLink
                href="http://rainmakerfamilysupport.com/"
                target="_blank"
                name="Support"
              >
                <QuestionMarkCircleIcon />
              </NavigationLink>
              <NavigationItem
                item={{
                  name: 'Settings',
                  href: '/profile',
                  icon: CogIcon,
                  current: location.pathname.includes('/profile'),
                  requiredRoles: ALL_ROLES
                }}
                currentUser={currentUser}
                onNavigate={() => setSidebarOpen(false)}
              />
              {'admin' === currentUser?.role || currentUser?.isAdmin ? (
                <div className="text-white hidden lg:block">
                  <SwitchRoleSelect currentUser={currentUser} position="top-center" size="sm" />
                </div>
              ) : null}
            </div>
          </div>
          <div className="pt-6 mt-6 border-t border-solid border-opacity-20 border-white-500 hidden lg:block">
            <div className="flex">
              <div className="flex-1 max-w-full overflow-hidden">
                <div className="flex items-center gap-3">
                  <div className="w-10 h-10 flex-shrink-0 rounded-full overflow-hidden bg-secondary flex items-center justify-center">
                    {currentUser?.photoUrl ? (
                      <img
                        src={currentUser?.photoUrl}
                        alt={currentUser?.fullName}
                        className="w-full h-full object-cover object-center"
                      />
                    ) : (
                      <UserSolidIcon className="text-white max-w-full mt-2" />
                    )}
                  </div>
                  <div className="flex-1 max-w-full overflow-hidden">
                    <p
                      className="text-base leading-20 text-white font-medium font-base truncate"
                      title={currentUser?.fullName}
                    >
                      <span>{currentUser?.fullName}</span>
                    </p>
                    <p
                      className="text-sm leading-20 text-white-100 font-medium font-base transition-colors truncate"
                      title={currentUser?.email}
                    >
                      <span>{currentUser?.email}</span>
                    </p>
                  </div>
                </div>
              </div>

              <div
                className="flex-shrink-0 -mt-2 w-9 h-9 text-white-800 hover:text-white transition-colors cursor-pointer"
                onClick={() => dispatch(logoutAsync())}
              >
                <LogoutIcon className="w-6 h-6 m-1" />
              </div>
            </div>
          </div>
        </div>
      </div>


      <div className="flex flex-col w-0 flex-1 overflow-hidden">
        <div className="flex-shrink-0 py-4 bg-primary lg:hidden">
          <div className="container">
            <div className="flex items-center gap-4 md:gap-6">
              <button
                type="button"
                className="flex-shrink-0 mr-2 text-white hover:text-secondary cursor-pointer"
                onClick={() => setSidebarOpen(true)}
              >
                <MenuAlt2Icon className="h-8 w-8" aria-hidden="true" />
              </button>
              <div className="flex-shrink-0 mx-auto">
                <Link to="/">
                  <img
                    className="w-auto"
                    src="/images/white-rainmaker-family-log.svg"
                    alt="Rainmakers Logo"
                  />
                </Link>
              </div>
              <div className="flex-shrink-0">
                {/* Profile dropdown */}
                <Menu as="div" className="relative z-10">
                  <div>
                    <Menu.Button className="max-w-xs flex items-center text-sm rounded-full focus:outline-none">
                      <div className="w-10 h-10 rounded-full overflow-hidden bg-secondary flex items-center justify-center">
                        {currentUser?.photoUrl ? (
                          <img
                            src={currentUser?.photoUrl}
                            alt={currentUser?.fullName}
                            className="w-full h-full object-cover object-center"
                          />
                        ) : (
                          <UserSolidIcon className="text-white max-w-full mt-2" />
                        )}
                      </div>
                    </Menu.Button>
                  </div>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-sm shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                      {userNavigation.map((item) => (
                        <Menu.Item key={item.name}>
                          {({ active }) => (
                            <Link
                              to={item.href}
                              className={classNames(
                                active ? 'bg-gray-100' : '',
                                'block px-4 py-2 text-sm text-gray-700'
                              )}
                            >
                              {item.name}
                            </Link>
                          )}
                        </Menu.Item>
                      ))}
                      <Menu.Item>
                        {({ active }) => (
                          <button
                            className={classNames(
                              active ? 'bg-gray-100' : '',
                              'w-full text-left block px-4 py-2 text-sm text-gray-700'
                            )}
                            onClick={() => dispatch(logoutAsync())}
                          >
                            Logout
                          </button>
                        )}
                      </Menu.Item>
                      {'admin' === currentUser?.role || currentUser?.isAdmin ? (
                        <div className="text-gray-700">
                          <SwitchRoleSelect currentUser={currentUser} />
                        </div>
                      ) : null}
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
            </div>
          </div>
        </div>

        <main id="main" className="flex-1 relative overflow-y-auto focus:outline-none">
          <div className="h-full">{children}</div>
        </main>
      </div>
    </div>
  )
}

export default DashboardTemplate
