import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ReactTooltip from 'react-tooltip'
import classNames from 'classnames';
import FormModal from '../../components/general/FormModal'
import Btn from '../../components/general/Btn';
import {
    PlusIcon,
} from "@heroicons/react/solid";
import CourseForm from './CourseForm';
import { CourseLoadingKeys, deleteCourseAsync, readManyCoursesAsync, reorderCoursesPositions } from './coursesListSlice'
import { arrayHasItems, getFilteredCoursesByRole, useDebounce } from '../../helpers';
import { CourseSearch } from '../../components/courses/CourseSearch'
import { MainLoader } from '../../components/Loaders/MainLoader'
import { BackToTop } from '../../components/courses/BackToTop';
import { CoursesTabs } from '../../components/courses/CoursesTabs';
import { ALL_TAB, COURSES_TAB, LESSONS_CONTENT_TAB, LESSONS_TITLES_TAB, MODULES_TAB } from '../../components/courses/consts';
import { LinkTabTitle } from '../../components/courses/LinkTabTitle';
import ModuleLessonCard from "../../components/courses/ModuleLessonCard"
import CourseCardSection from "../../components/courses/CourseSearchSection"
import CourseCardItem from "./CourseCardItem"
import AllCoursesList from "./AllCoursesList"

const CourseList = () => {
    const dispatch = useDispatch()
    const { 
        loading: courseListLoading, 
        loadingKeys: courseListLoadingKeys, 
        courses, 
        modules,
        lessonsTitles,
        lessonsContent,
        isFirstLoadComplete 
    } = useSelector(
        (state) => state.courseList
    )

    const pageParams = window?.location?.search ? new URLSearchParams(window?.location?.search) : null;

    const { currentUser } = useSelector((state) => state.auth)

    const [currentActiveTab, setCurrentActiveTab] = useState(pageParams?.get('activeTab') || ALL_TAB);

    const currentUserRole = currentUser?.role;
    const isAdmin = currentUserRole === 'admin'
    
    const [editingCourse, setEditingCourse] = useState(null);
    const [isReorderingMode, setIsReorderingMode] = useState(false);
    // search
    const [search, setSearch] = useState(pageParams?.get('search') ||'')

    const debouncedInputValue = useDebounce(search, 500);

    // search filter
    const [newCourseModalOpen, setNewCourseModalOpen] = useState(false);

    const [temporaryItems, setTemporaryItems] = useState();
    const [reorderCourses, setReorderCourses] = useState();
    const isSearchEmpty = debouncedInputValue === "";

    function enableReordering() {
        setIsReorderingMode(true);
        setReorderCourses(filteredCourses);
    }

    useEffect(() => {
        ReactTooltip.rebuild()
    }, [])

    useEffect(() => {
        if (!isAdmin) {
            setIsReorderingMode(false)
        }
      }, [currentUser?.role])
      
    useEffect(() => {
        dispatch(readManyCoursesAsync({ search: debouncedInputValue, itemsPerPage: 1000 }))
    }, [debouncedInputValue, dispatch])

    useEffect(() => {
        const params = new URLSearchParams({
            search: debouncedInputValue, 
            activeTab: currentActiveTab,
        });
        window?.history?.replaceState(null, "", `/courses?${params}`);
    }, [debouncedInputValue, currentActiveTab])

    const filteredCourses = getFilteredCoursesByRole(courses, currentUserRole || 'basic');
    const renderedCourses= isReorderingMode ? temporaryItems || reorderCourses : filteredCourses;

    const getListDataToRender = (data, dataTab) => currentActiveTab === ALL_TAB ? data.slice(0,3) : currentActiveTab === dataTab ? data : [];

    const coursesToRender = getListDataToRender(renderedCourses, COURSES_TAB);
    const modulesToRender = getListDataToRender(modules, MODULES_TAB);
    const lessonsTitlesToRender = getListDataToRender(lessonsTitles, LESSONS_TITLES_TAB);
    const lessonsContentToRender = getListDataToRender(lessonsContent, LESSONS_CONTENT_TAB);

    const onDragEnd = (result) => {
        setReorderCourses(result);
    }

    const saveSortOrder = () => {
        const courseWithNewPositions = reorderCourses.map((course, courseIndex) => {
            return {
                ...course,
                position: courseIndex + 1,
            };
        });
        dispatch(reorderCoursesPositions({ courses: courseWithNewPositions, search: debouncedInputValue, itemsPerPage: 1000 }))
        setIsReorderingMode(false)
    }

    const handleCourseEdit = (course) => {
        setNewCourseModalOpen(true);
        setEditingCourse(course);
    }
    
    const scrollToTop = () => {
        const mainBlock = document.getElementById('main');
        if (mainBlock) {
            mainBlock.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        }
    }

    const listIsLoading = () => {
        return courseListLoadingKeys.includes(CourseLoadingKeys.courses) && courseListLoading
    }

    const handleChangeTab = (tab) => {
        setCurrentActiveTab(tab);
        scrollToTop();
    }

    if (!isFirstLoadComplete) {
        return <MainLoader />
    }

    return (
        <>
            <BackToTop isLoading={listIsLoading()} />
            <div className="pt-7.5 md:pt-9.5 pb-6 md:pb-12">
                <div className="container h-full flex-col flex">
                    <div className="flex flex-wrap justify-between items-center gap-5 pb-8 md:pb-8.5">
                        <h1 className="w-full md:w-auto text-lg md:text-xl leading-38 font-semibold tracking-1 font-base">
                            Courses
                        </h1>
                        <div className="w-full md:w-auto flex flex-wrap items-center gap-6 sm:gap-4">
                            <CourseSearch
                                value={search}
                                onChange={setSearch}
                                disabled={isReorderingMode || (!arrayHasItems(courses) && !search)}
                                isReorderingMode={isReorderingMode}
                                placeholder={"Search"}
                            />
                            {isAdmin && isSearchEmpty && (
                                <div className="flex flex-wrap items-center gap-4 flex-col sm:flex-row w-full sm:w-auto">
                                    <div className="flex-grow-0 flex-shrink-0 w-full sm:w-auto">
                                        <Btn
                                            onClick={() => setNewCourseModalOpen(true)}
                                            row={true}
                                            disabled={isReorderingMode}
                                            full={true}
                                        >
                                            <div className="w-5 h-5 flex content-center justify-center relative">
                                                <div className={classNames(["bg-white bg-opacity-30 h-full w-full absolute z-10 rounded",
                                                        { 
                                                            "change-background-color-plus": !isReorderingMode
                                                        }
                                                    ])}>
                                                </div>
                                                <PlusIcon className="w-3 h-3 mt-1 z-20 fill-white" />
                                            </div>
                                            <span>Add New Course</span>
                                        </Btn>
                                    </div>
                                    {!isReorderingMode && (
                                        <div className="flex w-full sm:w-auto">
                                            <Btn onClick={() => enableReordering()} full={true}>
                                                Reorder Courses
                                            </Btn>
                                        </div>
                                    )}
                                    {isReorderingMode && (
                                        <div className="flex gap-2.5 rounded-sm w-full sm:w-auto">
                                            <div className="flex w-full sm:w-auto">
                                                <Btn onClick={() => saveSortOrder()} full={true}>
                                                    Save
                                                </Btn>
                                            </div>
                                            <div className="flex w-full sm:w-auto">
                                                <Btn variant="secondary" outline={true} onClick={() => setIsReorderingMode(false)} full={true}>
                                                    Cancel
                                                </Btn>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            )}
                        </div>
                    </div>
                    {!isSearchEmpty && 
                        <div className='mb-8'>
                            <CoursesTabs 
                                activeTab={currentActiveTab}
                                setActiveTab={(tab) => setCurrentActiveTab(tab)}
                                coursesCount={(courses || []).length}
                                modulesCount={(modules || []).length}
                                lessonsTitlesCount={(lessonsTitles || []).length}
                                lessonsContentCount={(lessonsContent || []).length}
                                isLoading={listIsLoading()}
                            />
                        </div>
                    }
                    {listIsLoading() && (
                        <div className="flex-auto">
                            <MainLoader />
                        </div>
                    )}
                    {!listIsLoading() && (
                        isSearchEmpty ? 
                            <AllCoursesList courses={renderedCourses} isReorderingMode={isReorderingMode} onDragEnd={onDragEnd} setTemporaryItems={setTemporaryItems} isAdmin={isAdmin} handleCourseEdit={handleCourseEdit} />
                            : currentActiveTab === ALL_TAB ? (
                            <section className='flex flex-col gap-8'>
                                {arrayHasItems(coursesToRender) && (
                                    <CourseCardSection>
                                        <LinkTabTitle
                                            onClickButton={() => handleChangeTab(COURSES_TAB)}
                                            title={"Courses"}
                                            buttonTitle={"View all courses"}
                                        />
                                        <div className='grid sm:grid-cols-2 md:grid-cols-3 gap-2'>
                                            {coursesToRender?.map((course) => 
                                                <CourseCardItem
                                                    key={`${course.id}-all`}
                                                    courseItem={course}
                                                    isReorderingMode={false}
                                                    onClickEdit={() => null}
                                                    isAdmin={isAdmin}
                                                />
                                            )}
                                        </div>
                                    </CourseCardSection>
                                )}
                                <div>
                                    {arrayHasItems(modulesToRender) && (
                                        <CourseCardSection>
                                            <LinkTabTitle
                                                onClickButton={() => handleChangeTab(MODULES_TAB)}
                                                title={"Modules"}
                                                buttonTitle={"View all modules"}
                                            />
                                            <div className='flex flex-col gap-2'>
                                                {modulesToRender?.map((module) => 
                                                    <ModuleLessonCard
                                                        key={`${module.id}-all`}
                                                        title={module.title}
                                                        breadcrumbs={[{ name: module.courseTitle, href: `courses/${module.courseSlug}`}]}
                                                        buttonTitle="View Module"
                                                        path={`courses/${module.courseSlug}/${module.id}`}
                                                    />
                                                )}
                                            </div>
                                        </CourseCardSection>
                                    )}
                                </div>
                                <div>
                                    {arrayHasItems(lessonsTitlesToRender) && (
                                        <CourseCardSection>
                                            <LinkTabTitle
                                                onClickButton={() => handleChangeTab(LESSONS_TITLES_TAB)}
                                                title={"Lessons Titles"}
                                                buttonTitle={"View all lessons (titles)"}
                                            />
                                            <div className='flex flex-col gap-2'>
                                                {lessonsTitlesToRender?.map((lesson) => 
                                                    <ModuleLessonCard
                                                        key={`${lesson.id}-all`}
                                                        title={lesson.title}
                                                        breadcrumbs={[{ name: lesson.courseTitle, href: `courses/${lesson.courseSlug}` }, { name: lesson.moduleTitle, href: `courses/${lesson.courseSlug}/${lesson.ModuleId}` }]}
                                                        buttonTitle="View Lesson"
                                                        path={`courses/${lesson.courseSlug}/${lesson.ModuleId}/${lesson.id}`}
                                                    />
                                                )}
                                            </div>
                                        </CourseCardSection>
                                    )}
                                </div>
                                <div>
                                    {arrayHasItems(lessonsContentToRender) && (
                                        <CourseCardSection>
                                            <LinkTabTitle
                                                onClickButton={() => handleChangeTab(LESSONS_CONTENT_TAB)}
                                                title={"Lessons Content"}
                                                buttonTitle={"View all lessons (content)"}
                                            />
                                            <div className='flex flex-col gap-2'>
                                                {lessonsContentToRender?.map((lesson) => 
                                                    <ModuleLessonCard
                                                        key={`${lesson.id}-all`}
                                                        title={lesson.title}
                                                        breadcrumbs={[{ name: lesson.courseTitle, href: `courses/${lesson.courseSlug}` }, { name: lesson.moduleTitle, href: `courses/${lesson.courseSlug}/${lesson.ModuleId}` }]}
                                                        buttonTitle="View Lesson"
                                                        path={`courses/${lesson.courseSlug}/${lesson.ModuleId}/${lesson.id}`}
                                                    />
                                                )}
                                            </div>
                                        </CourseCardSection>
                                    )}
                                </div>
                            </section>
                        ) : currentActiveTab === COURSES_TAB ? (
                            arrayHasItems(coursesToRender) ? (
                                <div className='grid sm:grid-cols-2 md:grid-cols-3 gap-4 pb-9 md:pb-9.5'>
                                    {coursesToRender?.map((course) => 
                                        <CourseCardItem
                                            key={`${course.id}-courses`}
                                            courseItem={course}
                                            isReorderingMode={false}
                                            onClickEdit={() => null}
                                            isAdmin={isAdmin}
                                        />
                                    )}
                                </div>
                            ) : null
                        ) : currentActiveTab === MODULES_TAB ? (
                            arrayHasItems(modulesToRender) ? (
                                <CourseCardSection>
                                    {modulesToRender?.map((module) => 
                                        <ModuleLessonCard
                                            key={`${module.id}-modules`}
                                            title={module.title}
                                            breadcrumbs={[{ name: module.courseTitle, href: `courses/${module.courseSlug}`}]}
                                            buttonTitle="View Module"
                                            path={`courses/${module.courseSlug}/${module.id}`}
                                        />
                                    )}
                                </CourseCardSection>
                            ) : null
                        ) : currentActiveTab === LESSONS_TITLES_TAB ? (
                            arrayHasItems(lessonsTitlesToRender) ? 
                            <CourseCardSection>
                                {lessonsTitlesToRender?.map((lesson) => 
                                    <ModuleLessonCard
                                        key={`${lesson.id}-lessons-titles`}
                                        title={lesson.title}
                                        breadcrumbs={[{ name: lesson.courseTitle, href: `courses/${lesson.courseSlug}` }, { name: lesson.moduleTitle, href: `courses/${lesson.courseSlug}/${lesson.ModuleId}` }]}
                                        buttonTitle="View Lesson"
                                        path={`courses/${lesson.courseSlug}/${lesson.ModuleId}/${lesson.id}`}
                                    />
                                )}
                            </CourseCardSection> : null
                        ) : currentActiveTab === LESSONS_CONTENT_TAB ? (
                            arrayHasItems(lessonsContentToRender) ? 
                            <CourseCardSection>
                                {lessonsContentToRender?.map((lesson) => 
                                    <ModuleLessonCard
                                        key={`${lesson.id}-lessons-content`}
                                        title={lesson.title}
                                        breadcrumbs={[{ name: lesson.courseTitle, href: `courses/${lesson.courseSlug}` }, { name: lesson.moduleTitle, href: `courses/${lesson.courseSlug}/${lesson.ModuleId}` }]}
                                        buttonTitle="View Lesson"
                                        path={`courses/${lesson.courseSlug}/${lesson.ModuleId}/${lesson.id}`}
                                    />
                                )}
                            </CourseCardSection> : null
                        ) : null
                    )}
                </div>
            </div>

            <FormModal
                open={newCourseModalOpen}
                setOpen={setNewCourseModalOpen}
                Form={CourseForm}
                editingItem={editingCourse}
                additionalFormData={{
                    search: debouncedInputValue
                }}
                onAfterClose={() => setEditingCourse(null)}
                deleteAction={async () => {
                    await dispatch(deleteCourseAsync({ course: editingCourse }))
                }}
            />

        </>
    )
}

export default CourseList
