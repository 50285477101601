import Btn from "../general/Btn"
import LoadingSpinner from "../general/LoadingSpinner"
import { ALL_TAB, COURSES_TAB, enumCoursesTabs, LESSONS_TITLES_TAB, LESSONS_CONTENT_TAB, MODULES_TAB } from "./consts"

export const LoaderTab = () => {
    return (
        <div className="h-full rounded-lg w-full absolute bg-white bg-opacity-70">
            <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                <LoadingSpinner color="purple-450" sizeClassNames="h-5 w-5" />
            </div>
        </div>
    )
}

export const CoursesTabs = ({
    activeTab = ALL_TAB,
    setActiveTab,
    coursesCount = 0,
    modulesCount = 0,
    lessonsTitlesCount = 0,
    lessonsContentCount = 0,
    isLoading = false
}) => {
    const getActiveTabClassname = (tab) => activeTab === tab ? "active-tab" : "non-active-tab";
    return (
        <section className="flex gap-2 flex-wrap justify-center md:justify-start">
            <Btn 
                variant={getActiveTabClassname(ALL_TAB)} 
                onClick={() => setActiveTab(ALL_TAB)}
                className={"inline-flex justify-center items-center gap-1 relative"}
            >
                <span>{enumCoursesTabs[ALL_TAB]}</span>
                {isLoading && <LoaderTab />}
            </Btn>
            <Btn 
                variant={getActiveTabClassname(COURSES_TAB)} 
                onClick={() => setActiveTab(COURSES_TAB)}
                className={"inline-flex justify-center items-center gap-1 relative"}
            >
                <span>{enumCoursesTabs[COURSES_TAB]}</span>
                <span>({coursesCount})</span>
                {isLoading && <LoaderTab />}
            </Btn>
            <Btn 
                variant={getActiveTabClassname(MODULES_TAB)} 
                onClick={() => setActiveTab(MODULES_TAB)}
                className={"inline-flex justify-center items-center gap-1 relative"}
            >
                <span>{enumCoursesTabs[MODULES_TAB]}</span>
                <span>({modulesCount})</span>
                {isLoading && <LoaderTab />}
            </Btn>
            <Btn 
                variant={getActiveTabClassname(LESSONS_TITLES_TAB)} 
                onClick={() => setActiveTab(LESSONS_TITLES_TAB)}
                className={"inline-flex justify-center items-center gap-1 relative"}
            >
                <span>{enumCoursesTabs[LESSONS_TITLES_TAB]}</span>
                <span>({lessonsTitlesCount})</span>
                {isLoading && <LoaderTab />}
            </Btn>
            <Btn 
                variant={getActiveTabClassname(LESSONS_CONTENT_TAB)} 
                onClick={() => setActiveTab(LESSONS_CONTENT_TAB)}
                className={"inline-flex justify-center items-center gap-1 relative"}
            >
                <span>{enumCoursesTabs[LESSONS_CONTENT_TAB]}</span>
                <span>({lessonsContentCount})</span>
                {isLoading && <LoaderTab />}
            </Btn>
        </section>
    )
}